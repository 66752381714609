import { Spin } from 'antd';
import styled from 'styled-components';

import { theme } from '@theme';

const { margin } = theme;

interface WrapperProps {
  isMobile: boolean;
}

export const Wrapper = styled.div`
  margin-top: ${margin.xl};
  margin-bottom: ${margin.xl};
`;

export const Main = styled.div`
  margin-top: ${margin.lg};
`;

export const Header = styled.div<WrapperProps>(
  ({ isMobile }) => `
  display: ${isMobile ? 'inline-block' : 'flex'};
  justify-content: space-between;
  align-items: ${isMobile ? 'start' : 'center'};
`,
);

export const Title = styled.div`
  display: flex;
  align-items: center;
`;

export const Loading = styled(Spin)`
  margin: 0 ${margin.lg};
`;
