import React, { useContext, FC } from 'react';

import { Spin } from 'antd';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { ROUTES, PRIVATE_ROUTES, PUBLIC_ROUTES } from '@utils/pages';

import { Redirect } from './redirect';
import { UserContext } from './userContext';

const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
`;

const PageLoader = () => (
  <Wrap>
    <Spin />
  </Wrap>
);

export const CustomRouter: FC<{ extraParams: string }> = ({
  children,
  extraParams,
}) => {
  const { user, userLoading } = useContext(UserContext);
  const { route: routeLink } = useRouter();

  const privateRoutes = PRIVATE_ROUTES.map((route) => route.route);
  const publicRoutes = PUBLIC_ROUTES.map((route) => route.route);
  const currentRoute = routeLink.replace(/\[(.+?)\]/g, ':$1');

  if (userLoading) return <PageLoader />;

  if (!user && privateRoutes.includes(currentRoute))
    return <Redirect to={ROUTES.SIGN_IN.route + extraParams} />;

  if (user && publicRoutes.includes(currentRoute))
    return <Redirect to={ROUTES.MENU.route + extraParams} />;

  if (currentRoute === '/') {
    return <Redirect to={ROUTES.MENU.route + extraParams} />;
  }

  return <>{children}</>;
};
