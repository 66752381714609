import React, { FC, ReactNode, useEffect, useState } from 'react';

import { Tenant } from '@codegen/generated/graphql';
import { SubLayout } from '@containers/SubLayout';
import { setTenantToAuth } from '@lib/firebase';
import {
  fetchTenant,
  getTenantFromStorage,
  setTenantInStorage,
} from '@utils/tenant';

import { ErrorPage } from '../../pages/_error';

export const TenantGuard: FC<{
  children: (props: { tenant: Tenant }) => ReactNode;
}> = ({ children }) => {
  const [tenant, setTenant] =
    useState<Tenant | undefined>(getTenantFromStorage);
  const [loading, setLoading] = useState(!tenant?.id);

  const loadTenantId = async () => {
    setLoading(true);
    try {
      const fetchedTenant = await fetchTenant();

      setTenant(fetchedTenant);
      setTenantInStorage(fetchedTenant);
      setTenantToAuth(fetchedTenant);
    } catch (e) {
      // TODO: Handle error
      setTenant(undefined);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!tenant?.id) {
      loadTenantId();
    }
  }, [tenant?.id]);

  if (loading && !tenant?.id) {
    return <SubLayout loading={loading} />;
  }

  if (tenant) {
    return <>{children({ tenant })}</>;
  } else {
    return <ErrorPage />;
  }
};
