import { FC } from 'react';

import { Text } from '@components/Text';

import { Wrapper, Header, Main, Title, Loading } from './styles';

interface Props {
  title?: string;
  titleSuffix?: React.ReactNode;
  titlePrefix?: React.ReactNode;
  rightActions?: React.ReactNode;
  loading?: boolean;
  isMobile?: boolean;
}

export const SubLayout: FC<Props> = ({
  children,
  title,
  titlePrefix,
  titleSuffix,
  rightActions,
  loading,
  isMobile,
}) => (
  <Wrapper>
    <Header isMobile={!!isMobile}>
      <Title>
        {titlePrefix}
        <Text
          size={isMobile ? 'h3' : 'h2'}
          marginL={titlePrefix ? 'md' : 'none'}
          marginR={titleSuffix ? 'md' : 'none'}
        >
          {title}
        </Text>
        {loading && <Loading />}
        {titleSuffix}
      </Title>
      <div>{rightActions}</div>
    </Header>
    <Main>{children}</Main>
  </Wrapper>
);
