import { theme, parseColor } from '@theme';

const { color, padding, margin, border, borderRadius, shadow } = theme;

export const GlobalStyle = () => (
  <style jsx global>{`
    @font-face {
      font-family: 'Benton-Sans';
      src: url('/fonts/benton-sans-300-normal.eot');
      src: url('/fonts/benton-sans-300-normal.eot') format('embedded-opentype'),
        url('/fonts/benton-sans-300-normal.woff') format('woff'),
        url('/fonts/benton-sans-300-normal.ttf') format('truetype');
      font-style: normal;
      font-weight: 300;
    }

    @font-face {
      font-family: 'Benton-Sans';
      src: url('/fonts/benton-sans-300-italic.eot');
      src: url('/fonts/benton-sans-300-italic.eot') format('embedded-opentype'),
        url('/fonts/benton-sans-300-italic.woff') format('woff'),
        url('/fonts/benton-sans-300-italic.ttf') format('truetype');
      font-style: italic;
      font-weight: 300;
    }

    @font-face {
      font-family: 'Benton-Sans';
      src: url('/fonts/benton-sans-400-normal.eot');
      src: url('/fonts/benton-sans-400-normal.eot') format('embedded-opentype'),
        url('/fonts/benton-sans-400-normal.woff') format('woff'),
        url('/fonts/benton-sans-400-normal.ttf') format('truetype');
      font-style: normal;
      font-weight: 400;
    }

    @font-face {
      font-family: 'Benton-Sans';
      src: url('/fonts/benton-sans-400-italic.eot');
      src: url('/fonts/benton-sans-400-italic.eot') format('embedded-opentype'),
        url('/fonts/benton-sans-400-italic.woff') format('woff'),
        url('/fonts/benton-sans-400-italic.ttf') format('truetype');
      font-style: italic;
      font-weight: 400;
    }

    @font-face {
      font-family: 'Benton-Sans';
      src: url('/fonts/benton-sans-500-normal.eot');
      src: url('/fonts/benton-sans-500-normal.eot') format('embedded-opentype'),
        url('/fonts/benton-sans-500-normal.woff') format('woff'),
        url('/fonts/benton-sans-500-normal.ttf') format('truetype');
      font-style: normal;
      font-weight: 500;
    }

    @font-face {
      font-family: 'Benton-Sans';
      src: url('/fonts/benton-sans-500-italic.eot');
      src: url('/fonts/benton-sans-500-italic.eot') format('embedded-opentype'),
        url('/fonts/benton-sans-500-italic.woff') format('woff'),
        url('/fonts/benton-sans-500-italic.ttf') format('truetype');
      font-style: italic;
      font-weight: 500;
    }

    @font-face {
      font-family: 'Benton-Sans';
      src: url('/fonts/benton-sans-700-normal.eot');
      src: url('/fonts/benton-sans-700-normal.eot') format('embedded-opentype'),
        url('/fonts/benton-sans-700-normal.woff') format('woff'),
        url('/fonts/benton-sans-700-normal.ttf') format('truetype');
      font-style: normal;
      font-weight: 700;
    }

    @font-face {
      font-family: 'Benton-Sans';
      src: url('/fonts/benton-sans-700-italic.eot');
      src: url('/fonts/benton-sans-700-italic.eot') format('embedded-opentype'),
        url('/fonts/benton-sans-700-italic.woff') format('woff'),
        url('/fonts/benton-sans-700-italic.ttf') format('truetype');
      font-style: italic;
      font-weight: 700;
    }

    @font-face {
      font-family: 'Benton-Sans';
      src: url('/fonts/benton-sans-900-normal.eot');
      src: url('/fonts/benton-sans-900-normal.eot') format('embedded-opentype'),
        url('/fonts/benton-sans-900-normal.woff') format('woff'),
        url('/fonts/benton-sans-900-normal.ttf') format('truetype');
      font-style: normal;
      font-weight: 900;
    }

    @font-face {
      font-family: 'Benton-Sans';
      src: url('/fonts/benton-sans-900-italic.eot');
      src: url('/fonts/benton-sans-900-italic.eot') format('embedded-opentype'),
        url('/fonts/benton-sans-900-italic.woff') format('woff'),
        url('/fonts/benton-sans-900-italic.ttf') format('truetype');
      font-style: italic;
      font-weight: 900;
    }

    * {
      box-sizing: border-box;
    }
    body,
    html {
      padding: 0;
      margin: 0;
      font-family: 'Benton-Sans', serif !important;
      font-size: 16px;
    }

    .ant-select-selector {
      background: #f9f6f1 !important;
      border: 1px solid #ece1d4 !important;
      border-radius: 25px !important;
      padding: 10px 20px !important;
      height: 42px !important;
    }

    .ant-select-selection-item {
      line-height: 22px !important;
    }

    input,
    .ant-input-affix-wrapper {
      background: #f9f6f1 !important;
      border: 1px solid #ece1d4 !important;
      border-radius: 25px !important;
      padding: 10px 20px !important;
    }

    .ant-input-affix-wrapper input {
      background: #f9f6f1 !important;
      border: none !important;
      border-radius: 0 !important;
      padding: 0 !important;
      margin-left: ${margin.md} !important;
    }

    .ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
      background: ${color.beige} !important;
      color: ${color.black} !important;
    }

    .ant-picker-cell-inner::before {
      border: 0 !important;
    }

    .ant-picker-ranges {
      justify-content: center;
      display: flex;
    }

    .ant-picker-ok button {
      background: none !important;
      border: 0;
      box-shadow: none !important;
    }

    .ant-picker-ok button:hover,
    .ant-picker-today-btn:hover {
      opacity: 0.8;
    }

    .ant-picker-ok span,
    .ant-picker-today-btn {
      color: ${color.mulledWine} !important;
    }

    .ant-select-selection-item img {
      margin-right: ${margin.sm};
    }

    .ant-select-item-option-content img {
      display: none;
    }

    .ant-select-item-option-content {
      color: ${color.white};
    }

    .ant-select-dropdown {
      padding: 0 !important;
    }

    .ant-select-dropdown,
    .ant-select-item {
      background: ${color.codGray} !important;
    }

    .ant-select-item:hover {
      background: ${parseColor(['beige', 0.2])} !important;
    }

    .ant-select-item-option-selected,
    .ant-select-item.active {
      background: ${color.beige} !important;
    }

    .ant-select-item-option-selected:hover {
      background: ${parseColor(['beige', 0.9])} !important;
    }

    .ant-select-item-option-selected .ant-select-item-option-content,
    .ant-select-item.active .ant-select-item-option-content {
      color: ${color.black} !important;
    }

    .ant-select-item:not(:last-child) {
      border-bottom: ${border.secondary};
    }

    .ant-table table {
      border-collapse: separate !important;
      border-spacing: 0 ${margin.md} !important;
    }

    .ant-table table,
    .ant-table-thead th,
    .ant-table-thead tr,
    .ant-table-thead {
      background: ${color.springWood} !important;
      border: 0 !important;
      color: ${color.gray} !important;
    }

    .ant-table-thead th {
      font-weight: 300 !important;
    }

    .ant-table-tbody tr,
    .ant-table-tbody td {
      background: ${color.white} !important;
      color: ${color.black} !important;
      font-weight: 300 !important;
      border: 0 !important;
    }

    .ant-table-tbody tr {
      box-shadow: ${shadow.light};
    }

    .ant-table-tbody td:first-child {
      border-top-left-radius: ${borderRadius.md};
      border-bottom-left-radius: ${borderRadius.md};
    }

    .ant-table-tbody td:last-child {
      border-top-right-radius: ${borderRadius.md};
      border-bottom-right-radius: ${borderRadius.md};
    }

    .ant-select-selection-search {
      color: ${color.black} !important;
    }

    .ant-select-item.invisible {
      display: none;
    }

    .ant-checkbox-inner,
    .ant-checkbox-checked::after {
      width: 18px !important;
      height: 18px !important;
      border-color: ${parseColor(['asparagus', 0.2])} !important;
    }

    .ant-radio-checked .ant-radio-inner,
    .ant-radio-inner::after {
      background-color: ${color.asparagus} !important;
      border-color: ${color.asparagus} !important;
    }

    .ant-radio-inner::after {
      background: url('/icons/check.svg') no-repeat;
      background-color: ${color.asparagus} !important;
      background-position: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .ant-tooltip-inner {
      padding: ${padding.lg} !important;
    }
    .ant-spin {
      display: flex !important;
    }

    .ant-form-item-has-error label > span {
      color: ${color.error} !important;
    }

    .ant-form-item-has-error input[type='text'],
    .ant-form-item-has-error input[type='password'] {
      border-color: red !important;
    }
    .ant-form-item-has-error input[type='number'] {
      border-color: red !important;
    }

    #__next {
      height: 100%;
    }
  `}</style>
);
