import * as Sentry from '@sentry/node';

export const init = () => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
      enabled: process.env.NODE_ENV === 'production',
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      environment: process.env.REACT_APP_CUSTOM_ENV,
      ignoreErrors: [
        '_error.js getInitialProps missing data at path',
        'Network request failed',
        'Network error',
      ],
    });
  }
};
