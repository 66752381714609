import { useEffect } from 'react';

import { useRouter } from 'next/router';

export const Redirect = ({ to }: { to: string }) => {
  const { push } = useRouter();

  useEffect(() => {
    push(to);
  }, [to, push]);

  return null;
};
