import { useEffect, useState } from 'react';

import { getSubdomain } from '@utils/helpers';

enum FacilitySubdomain {
  TCK = 'thecitykitch',
  LINE = 'thelinekitchens',
  MUNCHLABS = 'munchlabs',
  LINCOLN = 'thelincolnkitchen',
  CRAVE = 'order',
}

export const HOTJAR_PROJ_ID: { [key in FacilitySubdomain]: string } = {
  [FacilitySubdomain.TCK]: '3539962',
  [FacilitySubdomain.LINE]: '3539971',
  [FacilitySubdomain.MUNCHLABS]: '3539973',
  [FacilitySubdomain.LINCOLN]: '3539967',
  [FacilitySubdomain.CRAVE]: '3539961',
};

export const getHotjarId = (url: string) =>
  HOTJAR_PROJ_ID[url as FacilitySubdomain]; // typing

export const useHotjar = () => {
  const [hotjarId, setHotjarId] = useState('');
  useEffect(() => {
    if (window) {
      setHotjarId(getHotjarId(getSubdomain(window.location.hostname)));
    }
  }, []);

  return { hotjarId };
};
