import { useHotjar } from 'hooks/useHotjar';
import { AppPropsType } from 'next/dist/next-server/lib/utils';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'theme/GlobalStyle';
import 'antd/dist/antd.css';

import { TenantGuard } from '@components/TenantGuard/TenantGuard';
import { ApolloProviderComponent } from '@lib/apollo';
import { AppContextProvider } from '@lib/appContext';
import { CustomRouter } from '@lib/customRouter';
import { FacilityContextProvider } from '@lib/facilityContext';
import { FirebaseUserProvider } from '@lib/userContext';
import { theme } from '@theme';
import { GA_TRACKING_ID, IS_SSR, FB_Pixel_ID } from '@utils/constants';
import { init } from '@utils/sentry';
import { getTenantFromStorage } from '@utils/tenant';
import { Platform } from '@utils/types';

init();

declare global {
  interface Window {
    fbq: any;
  }
}

const MyApp = ({
  Component,
  pageProps,
  err,
}: AppPropsType & { err?: Error }) => {
  const hostname = !IS_SSR ? window.location.hostname : '';
  const isDev = hostname.includes('localhost');
  const isStaging = hostname.includes('staging');
  const platform = Platform.Catering;

  // TODO: ExtraParams haven't worked for a while now, fix adding selected kitchen to url
  const extraParams = '';

  const savedTenant = !IS_SSR ? getTenantFromStorage() : undefined; // DO NOT pass this tenant to any component below, only used the one from TenantGuard
  const { hotjarId } = useHotjar();

  return (
    <>
      <Head>
        <link
          rel="icon"
          href={savedTenant?.favicon ?? '/favicon.ico'}
          type="image/x-icon"
        />
        <link
          rel="shortcut icon"
          href={savedTenant?.favicon ?? '/favicon.ico'}
          type="image/x-icon"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1"
        />
        {savedTenant?.metaTitle && <title>{savedTenant.metaTitle}</title>}
        {!isDev && !isStaging && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
            />

            <script
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
          `,
              }}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WPDF2PR');`,
              }}
            />
            <noscript
              dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WPDF2PR"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
              }}
            />

            {/* Hotjar Tracking Code - Catering */}
            {hotjarId && (
              <script
                dangerouslySetInnerHTML={{
                  __html: `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[])
                  .push(arguments)}; h._hjSettings={hjid:${hotjarId},hjsv:6}; a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script'); r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                 a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
                }}
              />
            )}

            <script
              dangerouslySetInnerHTML={{
                __html: `!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window,document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', ${FB_Pixel_ID});
                fbq('track', 'PageView')
                `,
              }}
            />
            <noscript
              dangerouslySetInnerHTML={{
                __html: `<img
                    height="1"
                    width="1"
                    src="https://www.facebook.com/tr?id=${FB_Pixel_ID}&ev=PageView
                        &noscript=1"
                />`,
              }}
            />
          </>
        )}
      </Head>

      <TenantGuard>
        {({ tenant }) => {
          // You could pass tenant to any of the children if need be, tenant could contain theme for example in the future

          return (
            <ThemeProvider theme={theme}>
              <ApolloProviderComponent platform={platform}>
                <FirebaseUserProvider platform={platform} tenant={tenant}>
                  <CustomRouter extraParams={extraParams}>
                    <AppContextProvider
                      extraParams={extraParams}
                      platform={platform}
                      tenant={tenant}
                    >
                      <FacilityContextProvider>
                        <GlobalStyle />
                        <Component {...pageProps} err={err} />
                      </FacilityContextProvider>
                    </AppContextProvider>
                  </CustomRouter>
                </FirebaseUserProvider>
              </ApolloProviderComponent>
            </ThemeProvider>
          );
        }}
      </TenantGuard>
    </>
  );
};
export default MyApp;
