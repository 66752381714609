import { AuthProps } from './types';

export const INITIAL_VALUES: AuthProps = {
  user: null,
  setUser: (_) => undefined,

  userLoading: true,

  isSigningUp: false,
  setIsSigningUp: (_) => undefined,
};
